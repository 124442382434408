<template>
  <section class="userRoleList">
    <a-row class="bottom-content">
      <a-col :span="12" class="left-box">
        <a-table
          :loading="loading"
          :scroll="{
            y: tableHeight,
            scrollToFirstRowOnChange: true,
          }"
          class="left-table"
          :columns="userColumns"
          :data-source="staffInfoList"
          :pagination="pagination"
          row-key="id"
          :row-selection="{
            selectedRowKeys: selectedStaff,
            onChange: staffChange,
            type: 'radio',
          }"
        >
        </a-table>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="11" class="right-box">
        <a-table
          :scroll="{ y: 350, scrollToFirstRowOnChange: true }"
          class="right-table"
          :columns="roleColumns"
          :data-source="roleList"
          :pagination="false"
          row-key="roleId"
          :row-selection="{
            columnTitle: ' ',
            selectedRowKeys: selectedRoles,
            onSelect: rolesSelect,
            getCheckboxProps: getCheckboxPropsFunc,
          }"
        >
        </a-table>
        <div class="btn">
          <a-space>
            <a-button type="primary" @click="confirm" class="confirm-btn" v-auth="['system:user:confirm']"
            >保存
            </a-button>
            <a-button type="primary" @click="cancel" class="cancel-btn" v-auth="['system:user:confirm']"
            >取消
            </a-button>
            <a-button type="primary" @click="clearSelection" class="cancel-btn" v-auth="['system:user:clearSelection']"
            >清空选项
            </a-button>
          </a-space>
        </div>
      </a-col>
    </a-row>
  </section>
</template>

<script>
const userColumns = [
  {
    title: "员工号",
    dataIndex: "idNumber",
    ellipsis: true
  },
  {
    title: "姓名",
    dataIndex: "fullName",
    ellipsis: true
  },
  {
    title: "邮箱",
    dataIndex: "email",
    ellipsis: true
  },
];
const roleColumns = [
  {
    title: "角色Key",
    dataIndex: "roleKey",
    ellipsis: true
  },
  {
    title: "角色名称",
    dataIndex: "roleName",
    ellipsis: true
  },
  {
    title: "角色描述",
    dataIndex: "remark",
    ellipsis: true
  }
];
import {
  defineComponent,
  ref,
  reactive,
  nextTick,
  onMounted,
  onUnmounted,
  toRefs,
  onActivated
} from "vue";
import { message } from "ant-design-vue";
import { useTableHeight } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common.js";

let queryCache = null;
export default defineComponent({
  name: "index",
  setup() {
    const { $api } = useGlobalPropertyHook();
    const staffInfoList = ref([]);
    const roleList = ref([]);
    const loading = ref(false);
    let searchCondition = {};
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getStaffList(queryCache);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getStaffList(queryCache);
      }
    });

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight(true);
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    const staffState = reactive({
      selectedStaff: [],
      loading: false
    });
    const roleState = reactive({
      selectedRoles: [],
      loading: false
    });

    const staffChange = (selectedStaff) => {
      staffState.selectedStaff = selectedStaff;
      findRolesByStaffId(selectedStaff);
    };

    // 6、Admin, 2、RM,  5、LM, 7、Reviewer, 8、HRBP, 9、Team Leader
    const disabledList = ["6", "2", "5", "7", "8", "9"];

    //角色互斥
    const conflictRole = ref([])
    const getconflictRole = () => {
      $api.queryRoleMutexAndRole().then((res) => {
        conflictRole.value = res.data
      });
    }

    const rolesSelect = (record, selected, selectedRows) => {
      const selectKeys = selectedRows.map(item => item.roleId)
      if (selected) {
        let a = conflictRole.value.filter(item => record.roleId === item.role_id).map(item => item.mutex_role_id)
        let b = conflictRole.value.filter(item => record.roleId === item.mutex_role_id).map(item => item.role_id)
        const c = a.concat(b)
        const conflictList = selectedRows.filter(role => c.some((item) => role.roleId === item));
        if (conflictList.length > 0) {
          const conflictNameList = conflictList.map(item => item.roleName)
          message.warning(record.roleName + "和" + conflictNameList.join("、") + "互斥，不可以同时选择");
          roleState.selectedRoles = selectKeys.filter(item => item !== record.roleId);
        } else {
          roleState.selectedRoles = selectKeys;
        }
      } else {
        roleState.selectedRoles = selectKeys;
      }
    };

    //重置页码
    const resetCurrent = (queryData) => {
      queryCache = queryData ? queryData : {};
      if (queryCache.reset) {
        pagination.current = 1;
      }
    };

    // 获取staff-table数据
    const getStaffList = (queryData, isResetSelect = true) => {
      queryCache = queryData ? queryData : {};
      loading.value = true;
      const params = {
        condition: queryCache.condition,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize
      };
      $api.getStaffs(params).then((res) => {
        staffInfoList.value = res.data.resource;
        if (res.data.resource.length > 0) {
          if (isResetSelect || staffState.selectedStaff.length === 0) {
            staffState.selectedStaff = [res.data.resource[0].id];
          }
          findRolesByStaffId(staffState.selectedStaff);
        } else {
          clearRole();
        }
        pagination.total = res.data.total;
        loading.value = false;
      });
    };

    //获取role-table数据
    const getRoleList = () => {
      $api.getRoleInfo().then((res) => {
        roleList.value = res.data;
      });
    };

    // 根据staffId查询roles
    const findRolesByStaffId = (selectedStaff) => {
      const data = {
        staffId: selectedStaff[0],
        pageSize: pagination.pageSize,
        pageIndex: pagination.current
      };
      $api.getRoleIdsByStaffId(data).then((res) => {
        if (res.data.roleIds != undefined) {
          roleState.selectedRoles = res.data.roleIds.split(",");
        } else {
          clearRole();
        }
      });
    };

    const clearRole = () => {
      roleState.selectedRoles = [];
    };

    const confirm = () => {
      if (roleState.selectedRoles.length == 0) {
        message.error("保存失败！至少选择一个角色！");
      } else {
        const data = {
          userId: staffState.selectedStaff[0],
          roleIdList: roleState.selectedRoles
        };
        $api
          .updateUserRole(data)
          .then((res) => {
            findRolesByStaffId(staffState.selectedStaff);
            message.success("保存成功！");
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
            message.error("保存失败！");
          });
      }
    };

    const cancel = () => {
      findRolesByStaffId(staffState.selectedStaff);
    };

    const getCheckboxPropsFunc = (record) => {
      return {
        disabled: disabledList.indexOf(record.roleId) !== -1
      };
    };

    const clearSelection = () => {
      roleState.selectedRoles = [];
    };

    onMounted(() => {
      resetCurrent();
      getconflictRole();
    });

    onActivated(() => {
      // getStaffList(queryCache, false);
      getRoleList();
      getconflictRole();
    });

    return {
      userColumns,
      roleColumns,
      pagination,
      loading,
      staffInfoList,
      roleList,
      searchCondition,
      ...toRefs(staffState),
      ...toRefs(roleState),
      tableHeight,
      staffChange,
      resetCurrent,
      getStaffList,
      getRoleList,
      findRolesByStaffId,
      confirm,
      cancel,
      clearRole,
      getCheckboxPropsFunc,
      clearSelection,
      rolesSelect,
      getconflictRole,
    };
  }
});
</script>

<style scoped lang="less">

.btn {
  margin: 20px 20px 0;

  //.confirm-btn {
  //  margin-right: 20px;
  //}
}
</style>
