<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="handleSearch"
                    ref="filterLayoutRef">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="搜索" name="condition" class="rms-form-item">
              <a-input
                  v-model:value="formState.condition"
                  placeholder="员工号/员工姓名"
                  enter-button
                  allowClear
                  autoComplete="off"
              />
            </a-form-item>
          </a-form>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onActivated } from "vue";
import {cloneDeep} from "lodash";
export default defineComponent({
  name: "Filter",
  setup(props, { emit }) {
    const formRef = ref();
    const roleNameOps = ref([]);
    const formState = reactive({
      condition: "",
    });
    let currentState  = {}
    const updateParams =() => {
      currentState  = cloneDeep(formState)
    }

    const resetForm = () => {
      // formState.condition = "";
      formRef.value.resetFields();
      updateParams();
      emit("search", currentState, true);
    };

    const handleSearch = (isResetSelect) => {
      updateParams()
      emit("search", currentState, isResetSelect);
    };

    onActivated(() => {
      handleSearch(false);
    });

    return {
      formState,
      roleNameOps,
      formRef,
      resetForm,
      handleSearch,
      updateParams,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  box-sizing: border-box;
}
</style>
