<template>
  <!--  <div>人员角色关系管理</div>-->
  <section class="app-container">
    <Filter @search="selectSearch" @resetData="resetData"></Filter>
    <UserRoleList ref="userRoleListRef"></UserRoleList>
  </section>
</template>

<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import UserRoleList from "./components/UserRoleList.vue";

export default defineComponent({
  name: "maintainUser",
  components: {
    Filter,
    UserRoleList,
  },
  setup() {
    const userRoleListRef = ref([]);
    const selectSearch = (data, isResetSelect) => {
      if(isResetSelect) {
        data.reset = true;
        userRoleListRef.value.resetCurrent(data);
      }
      userRoleListRef.value.getStaffList(data, isResetSelect);
    };
    return {
      userRoleListRef,
      selectSearch,
    };
  },
});
</script>
